import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Share from "../components/share";

import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const blogPost = ({ data: { contentfulBlogs, contentfulSiteInformation } }) => {
  const {
    title,
    slug,
    featureImage,
    publishDate,
    description,
    seoDescription,
  } = contentfulBlogs;
  const { siteUrl } = contentfulSiteInformation;
  return (
    <Layout>
      <SEO
        title={title}
        description={seoDescription.seoDescription}
        keywords={[
          `Casa Amiga`,
          `Madre de día`,
          `Blog Dulces Miradas`,
          `${title}`,
        ]}
        image={featureImage.file.url}
      />
      <div className="site-container blog-post">
        <div className="container">
          <article className="post">
            {featureImage ? (
              <Img
                className="feature-img"
                fluid={featureImage.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            ) : (
              <div className="no-image"></div>
            )}
            <div className="details">
              <h1 className="title">{title}</h1>
              <span className="date">
                <i className="fas fa-calendar-alt"></i>{" "}
                {moment(publishDate).format("LL")}
              </span>
              <div
                dangerouslySetInnerHTML={{
                  __html: description.childMarkdownRemark.html,
                }}
              />
            </div>
            <Share title={title} url={`${siteUrl}/${slug}`} />
          </article>
        </div>
      </div>
    </Layout>
  );
};

export default blogPost;

export const pageQuery = graphql`
  query SinglePostQuery($slug: String!) {
    contentfulBlogs(slug: { eq: $slug }) {
      id
      title
      slug
      featureImage {
        fluid(maxWidth: 1500) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
        file {
          url
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      publishDate
      seoDescription {
        seoDescription
      }
    }
    contentfulSiteInformation {
      siteUrl
    }
  }
`;
