import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const Share = ({ title, url }) => {
  return (
    <div className="post-social">
      <h6 className="title is-6">Compartir:</h6>
      <FacebookShareButton
        url={url}
        className="button is-outlined is-rounded facebook"
      >
        <span className="icon">
          <i className="fab fa-facebook-f"></i>
        </span>
        <span className="text">Facebook</span>
      </FacebookShareButton>
      <TwitterShareButton
        url={url}
        className="button is-outlined is-rounded twitter"
        title={title}
      >
        <span className="icon">
          <i className="fab fa-twitter"></i>
        </span>
        <span className="text">Twitter</span>
      </TwitterShareButton>
      <WhatsappShareButton
        url={url}
        className="button is-outlined is-rounded whatsapp"
        title={title}
      >
        <span className="icon">
          <i className="fab fa-whatsapp"></i>
        </span>
        <span className="text">WhatsApp</span>
      </WhatsappShareButton>
    </div>
  );
};

export default Share;
